import { createRouter, createWebHistory } from 'vue-router';

import { routes } from './routes.js';

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  scrollBehavior: (to, _, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.query?.['is-show-more'] || to.query?.scrollTo) {
      return;
    }

    if (to.hash) {
      return {
        el: to.hash,
        top: 35,
        behavior: 'smooth',
      };
    }

    return {
      top: 0,
      behavior: 'smooth',
    };
  },
});

export default router;
