<script setup>
import { ref } from 'vue';

const isShowPoppup = ref(false);
const consentPropName = 'cookies_consent';
const shouldShowPopup = () => !localStorage.getItem(consentPropName);
const saveToStorage = (answer) => localStorage.setItem(consentPropName, answer);

const handleButtonClick = (answer) => {
  saveToStorage(answer);
  isShowPoppup.value = false;
};

if (shouldShowPopup()) {
  isShowPoppup.value = true;
}
</script>

<template>
  <div v-if="isShowPoppup" class="wrapper">
    <div class="left-margins">
      <p class="info-text text-body-news">
        Our website stores cookies on your computer. These cookies are used to
        improve and provide a more personalised experience to you. To find out
        more about the cookies we use, see our Privacy and Cookie Policy.
      </p>
      <div class="buttons-wrapper">
        <button
          class="transparent-button text-button"
          @click="handleButtonClick(false)"
        >
          Decline
        </button>
        <button
          class="black-button btn_animation text-button"
          @click="handleButtonClick(true)"
        >
          Accept
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  background-color: var(--color-bg-light-grey);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  & > div {
    padding: 52px 0;
    gap: 3.2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 900px) {
      flex-direction: column;
    }
  }
}

.info-text {
  max-width: 92.4rem;
  flex: 1;
}

.buttons-wrapper {
  gap: 2.4rem;
  display: flex;
  flex-direction: row;
}
</style>
