import { companyRouteNames, rootRouteNames } from '@/constants/routeNames';

const getComponent = (item) => {
  return item.component || item.title.replace(/\s/g, '');
};

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Main/Home.vue'),
  },
  {
    path: rootRouteNames.privacyPolicy,
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy/Home.vue'),
  },
  {
    path: companyRouteNames.careers,
    name: 'Careers',
    component: () => import('../views/Careers/Home.vue'),
  },
  {
    path: '/just-future',
    name: 'JustFuture',
    component: () => import('../views/JustFuture/Home.vue'),
  },
  {
    path: '/use-cases',
    name: 'Use cases',
    component: () => import('../views/UseCases/Home.vue'),
    children: [],
  },
  {
    path: '/use-cases/:projectName',
    component: () => import('../views/ProjectPage/Home.vue'),
  },
];
