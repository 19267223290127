export const scrollToTarget = (event, targetName) => {
  const target = document.querySelector(
    `[ref=${targetName.replace(/\s|&/g, '')}]`,
  );
  const top = target?.offsetTop;

  window.scroll({
    top: top - 90,
    left: 0,
    behavior: 'smooth',
  });
};

const scrollToSection = (_, targetName) => {
  const target = document.querySelector(`[ref=${targetName}]`);
  const top = target.offsetTop;

  window.scroll({
    top: top + 50,
    left: 0,
    behavior: 'smooth',
  });
};

export const scrollToTargetSection = {
  mounted(el, binding) {
    el.addEventListener(
      'click',
      scrollToSection.bind(null, null, binding.value),
    );
  },
  beforeUnmount(el) {
    el.removeEventListener('click', scrollToSection);
  },
};

export const vScrollTo = {
  mounted(el, binding) {
    el.addEventListener(
      'click',
      scrollToTarget.bind(null, null, binding.value),
    );
  },
  beforeUnmount(el) {
    el.removeEventListener('click', scrollToTarget);
  },
};
