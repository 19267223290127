export const rootRouteNames = {
  privacyPolicy: '/privacy-policy',
};

export const serviceRouteNames = {
  BespokeSoftwareDevelopment: '/services/bespoke-software-development',
  SystemIntegrationAndAPIDevelopment:
    '/services/system-integration-and-api-development',
  CloudMigration: '/services/cloud-migration',
  MobileAppDevelopment: '/services/mobile-app-development',
  ITSupport: '/services/it-support',
  ITOutstaffing: '/services/it-outstaffing',
};

export const indusctiesRouteNames = {
  DigitalHR: '/industries/digital-hr',
  eCommerce: '/industries/e-commerce',
  UrbanPublicSector: '/urban-public-sector',
};

export const companyRouteNames = {
  careers: '/careers',
};
