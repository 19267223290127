import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/css/app.scss';
import { scrollTarget } from '@/directives/scrollTarget';

createApp(App)
  .directive('scrollTarget', scrollTarget)
  .use(router)
  .mount('#app');
