<script setup>
import { rootRouteNames } from '@/constants/routeNames';

const contacts = [
  {
    country: 'Cyprus',
    email: 'cyprus@jit.software',
    phone: '+357 95 142212',
    address: 'Ilia Papakyriakou, 16, 2nd floor, Egkomi, 2415, Nicosia, Cyprus',
  },
  {
    country: 'Serbia',
    email: 'serbia@jit.software',
    phone: '+381 11 451 94 59',
    address: 'Obilićev venac 8, apt. 3, 11000, Belgrade, Serbia',
  },
];
</script>

<template>
  <footer>
    <div v-scroll-target="'contacts'" class="wrapper left-margins">
      <div class="footer-logo-block">
        <img
          src="@/assets/svg/footer-logo.svg"
          alt="logo"
          class="mb32"
          width="192"
          height="48"
        />
        <p class="caption text-dark-grey mb16">
          Copyright © 2023 Just in Time Software.<br />All rights reserved.
        </p>
        <router-link
          target="_blank"
          :to="rootRouteNames.privacyPolicy"
          class="link caption"
          >Privacy Policy</router-link
        >
      </div>
      <div class="footer-contact-wrapper">
        <div
          v-for="contact in contacts"
          :key="contact.country"
          class="footer-contact-block"
        >
          <img
            src="@/assets/svg/ico-location.svg"
            alt="location"
            width="48"
            height="48"
          />
          <div class="footer-contact-block-info">
            <p class="text-body-strong text-purple">{{ contact.country }}</p>
            <a :href="`mailto:${contact.email}`" class="text-body-strong mb4">{{
              contact.email
            }}</a>
            <a :href="`tel:${contact.phone}`" class="text-body-strong">{{
              contact.phone
            }}</a>
            <p class="text-body-news">{{ contact.address }}</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
footer {
  display: flex;
  border-top: 1px solid var(--color-gray-light);
  padding: 8rem 0 10.5rem;
}

.wrapper {
  display: flex;
  flex: 1;
  justify-content: space-between;
  gap: 3.2rem;

  @media (max-width: 1130px) {
    flex-direction: column;
  }
}

.footer-contact-wrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  gap: 24px;

  @media (max-width: 1130px) {
    justify-content: flex-start;
  }

  @media (max-width: 710px) {
    flex-direction: column;
    gap: 24px;
  }
}

.footer-logo-block {
  display: flex;
  flex-direction: column;
  width: 28rem;
}

.footer-contact-block {
  display: flex;
  flex-direction: row;
  max-width: 48rem;
  min-width: 32rem;
  width: 40%;

  @media (max-width: 1130px) {
    width: 100%;
    min-width: inherit;
  }

  &-info {
    display: flex;
    flex-direction: column;
    margin-left: 2.4rem;
  }
}
</style>
