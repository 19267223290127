<template>
  <svg :width="width" :height="height" viewBox="0 0 56 8" :fill="color">
    <path
      d="M52.1023 0L51.5449 0.5572L54.4994 3.6H0V4.4H54.4994L51.5449 7.4292L52.1023 8L56 4L52.1023 0Z"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    width: {
      type: Number,
      default: 56,
    },
    height: {
      type: Number,
      default: 8,
    },
    color: {
      type: String,
      default: '#fff',
    },
  },
});
</script>
